.h6 {
    font-size: 18px;
}

.input-bottom-border {
    border: 0px;
    border-bottom: 1px solid #ced4da;
}
a:hover {
    text-decoration: none;
}
.admin-back-btn {
    color: #8c8c8c;
    font-family: 'Spoqa Han Sans Neo',
    'sans-serif';
    border: 2px solid #8c8c8c;
    border-radius: 7px;
    padding: 10px 20px 6px;
    width: 125px;
    font-size: 16px;
    font-weight: 500;
}
.tutorial-btn {
    color: #8c8c8c;
    font-family: 'Spoqa Han Sans Neo',
        'sans-serif';
    border: 2px solid #8c8c8c;
    border-radius: 7px;
    padding: 10px 20px 6px;
    width: 138px;
    font-size: 16px;
    font-weight: 500;
}
.btn-main-color {
    border-radius: 5px !important;
    background-color: #30A9DE !important;
    color: #fff !important;
    border: 1px solid transparent !important;
}

.background-main-color {
    background-color: #30A9DE;
}

.light-font {
    font-size: 14px;
    font-weight: 400;
    color: #566270;
}

.pdf-modal-list {
    overflow: auto;
}

.pdf-modal-list li {
    border-bottom: 1px solid #ced4da;
    padding-bottom: 5px;
}

.pdf-modal-list li:last-child {
    border-bottom: 0px;
    padding-bottom: 0px;
}

/* 관리자 메뉴 */

.admin-menu-item {
    color: #000;
    font-family: 'Spoqa Han Sans Neo',
    'sans-serif';
    font-size: 18px;
    line-height: 50px;
    font-weight: 300;
}
.admin-menu-item.active {
    font-weight: 700;
}
.admin-menu-item:hover {
    color: #000;
}
.admin-menu-line {
    height: 1px;
    background-color: #566270;
    margin: 20px 0px;
}

/* 검색바 및 상단 공통*/
.admin-select-container {
    display: flex;
    justify-content: flex-end;
    padding: 30px 20px 0px;
}
.admin-search-btn-container {
    background: transparent;
    border: 1px solid #ced4da;
    border-left: 0px;
}

/* 테이블 */
.table thead th {
    border-bottom: 1px solid #333;
    border-top: 1px solid #333;
}
.table td {
    border-top: 0px;
    border-bottom: 1px solid #c4c4c4;
}

/* 주문 조회 */

.admin-case-order-header {
    padding: 20px;
}
.admin-case-order-box-section {
    display: flex;
    flex-wrap: wrap;
}
.admin-case-order-box-wrapper {
    display: flex;
    width: 100%;
    padding-top: 20px;
}
.admin-case-order-box-container {
    display: flex;
    border-left: 1px solid #c4c4c4;
    border-right: 1px solid #c4c4c4;
}
.admin-case-order-box-container.left {
    border-right: 0px;
}
.admin-case-order-box-title {
    min-width: 130px;
    background-color: #f4f5f6;
    border-bottom: 1px solid #c4c4c4;
    border-right: 1px solid #c4c4c4;
    text-align: center;
}
.admin-case-order-box-content {
    border-bottom: 1px solid #c4c4c4;
    text-align: left;
}
.admin-case-order-box {
    padding: 10px 20px;
    border-top: 1px solid #c4c4c4;
}

.admin-case-order-before-container {
    display: flex;
    border: 1px solid #c4c4c4;
}
.admin-case-order-before-title {
    border-right: 1px solid #c4c4c4;
    background: #f4f5f6;
    padding: 10px 30px;
    
}
.admin-case-order-before-content {
    padding: 10px 30px;
}

.admin-case-order-table th{
    width: 130px;
    background: #f4f5f6;
    border: 1px solid #c4c4c4;
    text-align: center;
}
.admin-case-order-table td {
    width: 265px;
}
.admin-case-order-curriculum-container {
    display: flex;
    border: 1px solid #c4c4c4;
}
.admin-case-order-curriculum-title {
    background: #f4f5f6;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    border-right: 1px solid #c4c4c4;
}
.admin-case-order-curriculum-grade {
    padding: 20px;
    line-height: 35px;
    border-right: 1px solid #c4c4c4;
    color: #777;
    width: 15%;
}
.admin-case-order-empty-curriculum {
    padding: 20px;
    line-height: 35px;
    border-right: 1px solid #c4c4c4;
    color: #777;
    width: 100%;
}
.admin-case-order-curriculum-grade .active {
    color: #000;
    font-weight: 700;
}
.admin-case-order-curriculum-contents {
    padding: 30px;
    width: 70%;
}

.admin-case-order-search-container {
}

.table-name-data {
    display: inline-block;
    max-width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
/* 주제 목록 */

.ss-filter-container {
    display: flex;
    padding: 15px;
    flex-wrap: wrap;
}
.ss-filter-box {
    width: 100%;
    height: 50px;
    margin-top: 15px;
}
.ss-filter-btn {
    border: 1px solid #30A9DE;
    border-radius: 8px;
    color: #30A9DE;
    background: transparent;
}
.ss-filter-btn.active {
    background: #30A9DE;
    color: #fff;
}
.ss-filter-btn.inactive {
    margin-left: 15px;
}

/* 주제 검수 */

.admin-study-subject-header-container {
    margin-top: 30px;
}
.admin-study-subject-header {
    margin-bottom: 15px;
    font-size: 18px;
}
.admin-study-subject-header span {
    font-weight: 600;
    margin-right: 10px;
}

/* RA 관리 */

.admin-ra-profile-modal-table {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #c4c4c4;
    border-bottom: 0px;
    width: 100%;
}
.admin-ra-profile-modal-tr {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #c4c4c4;
}
.admin-ra-profile-modal-title {
    background: #f4f5f6;
    width: 40%;
    padding: 10px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin-ra-profile-modal-title.half {
    width: 50%
}
.admin-ra-profile-modal-content {
    width: 60%;
    padding: 10px;
    padding-left: 20px;
    line-height: 21px;
}
.admin-ra-profile-modal-content.half {
    width: 50%
}
.admin-ra-profile-modal-tr .w-33 {
    width: 33.3%;
    display: flex;
}
.admin-ra-profile-modal-tr .w-25 {
    width: 25%;
    display: flex;
}
.admin-ra-profile-modal-content.main-color span{
    color: #30A9DE;
    font-weight: 600;
}
.admin-ra-profile-modal-content.danger-color span {
    color: #EF5772;
    font-weight: 600;
}